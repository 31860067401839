.app-button-back {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 100%;
  color: $light;
  i {
    transition: .25s;
  }
  &:hover i {
    transform: translateX(-25%);
  }
}

.app-flowcard {
  .app-flowcard-btn-start {
    color: $primary;
    transition: .25s;
    font-weight: bold;
    z-index: 1;
    &:hover {
      color: $light;
      background-color: mix($background, $secondary, 20%);
    }
  }
  .app-flowcard-btn-end {
    color: $primary;
    transition: .25s;
    font-weight: bold;
    z-index: 1;
    &:hover {
      color: $light;
      background-color: mix($primary, $secondary, 80%);
    }
  }
  .app-flowcard-btn-delete {
    color: $primary;
    transition: .25s;
    z-index: 1;
    &:hover {
      color: $light;
      background-color: mix($negative, $secondary, 80%);
    }
  }
}

// General overwrite of quasar's uppercase transform for q-btn - makes no-caps property obsolete
.q-btn, .q-btn-dropdown, .q-tab {
  text-transform: none;
}

.app-action-btn,
.app-expand-btn,
.app-delete-btn {
  color: $secondary;
  background-color: #e3f6f8; // #29D3BF1A
  font-weight: 600;
  transition: .2s;
  border-radius: .25rem;
  &.app-highlighted-btn {
    color: $background2;
    background-color: $secondary;
  }
  &.app-grey {
    background-color: $background;
    color: $dark;

    &:hover {color:initial}
  }
  &.app-btn-contrast {
    background-color: $background2;
    color: $dark;
  }
  &:hover {
    color: $light;
    background-color: #7FE5D9;
  }
  &:focus {
    background-color: #15B9A7;
    color: $light;
  }
}
.app-delete-btn {
  background-color: #f8e2e2;
  color: $negative;

  &:hover, &:focus {
    color: $light;
    background-color: $negative;
  }
}

.q-btn-dropdown--split .q-btn-dropdown__arrow-container:not(.q-btn--outline) {
  border-left: 1px solid transparentize($secondary, .75);
}

body.body--dark {
  .app-action-btn,
  .app-expand-btn {
    &.app-btn-contrast {
      background-color: $dark;
      color: $light;
    }
    &:not(.app-highlighted-btn) {
      background-color: #142624;
    }
    &.app-grey {
      color: $secondary;

      &:hover {color: $light}
    }
  }
  .app-flowcard-btn-start {
    color: $primary-dark-page-font;
    &:hover {
      background-color: mix($background, $secondary-dark-page, 20%);
    }
  }
  .app-flowcard-btn-end {
    color: $primary-dark-page-font;
    &:hover {
      background-color: mix($primary, $secondary-dark-page, 80%);
    }
  }
  .app-flowcard-btn-delete {
    color: $secondary-dark-page;
    &:hover {
      background-color: mix($negative, $secondary-dark-page, 80%);
    }
  }
  .app-delete-jdm {
    color: mix($negative, $light, 30%);
    background-color: mix($negative, $dark, 20%);
    &:hover {
      color: $light;
      background-color: $negative;
    }
  }
  .app-action-btn, .app-expand-btn {
    &.app-grey {
      color: $secondary;

      &:hover {color:$light}
    }
  }
  .q-btn.bg-primary {
    background-color: $primary-dark-page !important;
  }
  .q-btn.disabled {
    opacity: .3 !important;
  }
}
